<template>
    <section class="flex search-bar">
         <div style="width: 130px">
            <SelectNested v-model="effectiveRange" :options="ranges" title="Effective Range" class="max-height-1000px" />
        </div>

        <div style="width: 130px">
            <Date v-model="search.effectiveFrom" title="Effective From" />
        </div>

        <div style="width: 130px">
            <Date v-model="search.effectiveTo" title="Effective To" />
        </div>
         <div style="width: 130px">
            <SelectNested v-model="expiresRange" :options="ranges" title="Expires Range" class="max-height-1000px" />
        </div>
        <div style="width: 130px">
            <Date v-model="search.expiresFrom" title="Expires From" />
        </div>
        <div style="width: 130px">
            <Date v-model="search.expiresTo" title="Expires to" />
        </div>
        <CheckBoxOptional v-model="search.isBusiness" title="Is Business" />
        <CheckBoxOptional v-model="search.newBusiness" title="New Business" />
        <CheckBoxOptional v-model="search.renewed" title="Renewed" />
        <div style="width: 170.36px">
              <SearchableCarrier />
        </div>

        <div  style="width: 200px">
            <Select v-model.trim="search.type" class="max-height-1000px" :options="types" title="Type" />
        </div>
        <div style="width: 130px">
            <Date v-model="search.cancelledFrom" title="Cancelled From" />
        </div>
        <div style="width: 130px">
            <Date v-model="search.cancelledTo" title="Cancelled To" />
        </div>
        <div style="width: 170.36px">
            <Money v-model.trim="search.premiumFrom" title="Premium From" />
        </div>
        <div style="width: 170.36px">
            <Money v-model.trim="search.premiumTo" title="Premium To" />
        </div>
        <div style="width: 170.36px">
            <Percent v-model.trim="search.adjustedFrom" title="Adjusted From" />
        </div>
        <div style="width: 170.36px">
            <Percent v-model.trim="search.adjustedTo" title="Adjusted To" />
        </div>

        <div class="input-container" style="width: 170.36px">
            <label> Notes </label>
            <input v-model.trim="search.notes" />
        </div>

        <div class="input-container" style="width: 159.19px">
            <button class="reset w-full" @click="resetSearch">Reset</button>
        </div>
        <div class="input-container" style="width: 159.19px">
            <button class="search w-full" @click="triggerSearch">Search</button>
        </div>
    </section>
</template>

<script>
import dayjs from 'dayjs';

import CheckBoxOptional from '../../components/CheckBoxOptional';
import Date from '@/components/Date';
import Select from '@/components/Select';
import Money from '@/components/Money';
import Percent from '@/components/Percent';
import SelectNested from '@/components/SelectNested';
import SearchableCarrier from './SearchableCarrier'


export default {
    components: { CheckBoxOptional, Date, Select, Money, Percent, SelectNested, SearchableCarrier },
    data() {
        return {
           ranges: ['', {title: 'Period', options: ['This month', 'Last month', 'This year', 'Last year']}, {title: 'Exact', options: ['This month', 'Last month', 'This year', 'Last year']}],
        };
    },

    computed: {
        search() {
            return this.$store.getters['performance/getSearch'];
        },

        types() {
           return  this.$store.getters['policies/getTypes']();
        },

        effectiveRange: {
            get() {
                return this.search.effectiveRange;
            },
            set(v) {
                const set = {
                    effectiveRange: v,
                };

                // clone string
                v = v.toLowerCase();

                if (v) {
                    let effectiveFrom;
                    let effectiveTo;
                    // No need to add timezone, by default dayjs treat date as local
                    if (v === 'exact - this month') {
                        effectiveFrom = dayjs().date(1).format('M/D/YYYY');
                        effectiveTo = dayjs().date(dayjs().daysInMonth()).format('M/D/YYYY');
                    } else if (v === 'exact - this year') {
                        effectiveFrom = dayjs().dayOfYear(1).format('M/D/YYYY');
                        effectiveTo = dayjs().dayOfYear(365).format('M/D/YYYY');
                    } else if (v === 'exact - last month') {
                        effectiveFrom = dayjs().subtract(1, 'month').date(1).format('M/D/YYYY');
                        effectiveTo = dayjs().subtract(1, 'month').date(dayjs().subtract(1, 'month').daysInMonth()).format('M/D/YYYY');
                    } else if (v === 'exact - last year') {
                        effectiveFrom = dayjs().subtract(1, 'year').dayOfYear(1).format('M/D/YYYY');
                        effectiveTo = dayjs().subtract(1, 'year').dayOfYear(365).format('M/D/YYYY');
                    } else if (v === 'period - this month') {
                        effectiveFrom = dayjs().format('M/D/YYYY');
                        effectiveTo = dayjs().add(1, 'month').subtract(1, 'days').format('M/D/YYYY');
                    } else if (v === 'period - last month') {
                        effectiveFrom = dayjs().subtract(1, 'month').subtract(1, 'days').format('M/D/YYYY');
                        effectiveTo = dayjs().format('M/D/YYYY');
                    } else if (v === 'period - this year') {
                        effectiveFrom = dayjs().format('M/D/YYYY');
                        effectiveTo = dayjs().add(1, 'year').subtract(1, 'days').format('M/D/YYYY');
                    } else if (v === 'period - last year') {
                        // effectiveFrom = dayjs().subtract(1, 'year').subtract(1, 'days').format('M/D/YYYY');
                        // effectiveTo = dayjs().format('M/D/YYYY');
                        effectiveFrom = dayjs().subtract(1, 'year').format('M/D/YYYY');
                        effectiveTo = dayjs().subtract(1, 'days').format('M/D/YYYY');
                    }

                    set.effectiveFrom = effectiveFrom;
                    set.effectiveTo = effectiveTo;
                }

                this.$store.commit('performance/setSearch', set);
            },
        },

          expiresRange: {
            get() {
                return this.search.expiresRange;
            },
            set(v) {
                const set = {
                    expiresRange: v,
                };

                // clone string
                v = v.toLowerCase();

                if (v) {
                    let expiresFrom;
                    let expiresTo;
                    // No need to add timezone, by default dayjs treat date as local
                    if (v === 'exact - this month') {
                        expiresFrom = dayjs().date(1).format('M/D/YYYY');
                        expiresTo = dayjs().date(dayjs().daysInMonth()).format('M/D/YYYY');
                    } else if (v === 'exact - this year') {
                        expiresFrom = dayjs().dayOfYear(1).format('M/D/YYYY');
                        expiresTo = dayjs().dayOfYear(365).format('M/D/YYYY');
                    } else if (v === 'exact - last month') {
                        expiresFrom = dayjs().subtract(1, 'month').date(1).format('M/D/YYYY');
                        expiresTo = dayjs().subtract(1, 'month').date(dayjs().subtract(1, 'month').daysInMonth()).format('M/D/YYYY');
                    } else if (v === 'exact - last year') {
                        expiresFrom = dayjs().subtract(1, 'year').dayOfYear(1).format('M/D/YYYY');
                        expiresTo = dayjs().subtract(1, 'year').dayOfYear(365).format('M/D/YYYY');
                    } else if (v === 'period - this month') {
                        expiresFrom = dayjs().format('M/D/YYYY');
                        expiresTo = dayjs().add(1, 'month').subtract(1, 'days').format('M/D/YYYY');
                    } else if (v === 'period - last month') {
                        expiresFrom = dayjs().subtract(1, 'month').subtract(1, 'days').format('M/D/YYYY');
                        expiresTo = dayjs().format('M/D/YYYY');
                    } else if (v === 'period - this year') {
                        expiresFrom = dayjs().format('M/D/YYYY');
                        expiresTo = dayjs().add(1, 'year').subtract(1, 'days').format('M/D/YYYY');
                    } else if (v === 'period - last year') {
                        expiresFrom =dayjs().subtract(1, 'year').format('M/D/YYYY');
                        expiresTo = dayjs().subtract(1, 'days').format('M/D/YYYY');
                    }

                    set.expiresFrom = expiresFrom;
                    set.expiresTo = expiresTo;
                }

                this.$store.commit('performance/setSearch', set);
            },
        },
        // effective: {
        //     get() {
        //         return this.search.effective;
        //     },
        //     set(v) {
        //         const set = {
        //             effective: v,
        //         };

        //         if (v) {
        //             set.range = '';
        //         }

        //         // const expires = this.addOneYear(v);

        //         // if (!this.expires && expires) {
        //         //     set.expires = expires;
        //         // }

        //         this.$store.commit('performance/setSearch', set);
        //     },
        // },
        // expires: {
        //     get() {
        //         return this.search.expires;
        //     },
        //     set(v) {
        //         const set = {
        //             expires: v,
        //         };

        //         if (v) {
        //             set.range = '';
        //         }

        //         this.$store.commit('performance/setSearch', set);
        //     },
        // },
    },

    async mounted() {
        window.history.replaceState({}, document.title, window.location.pathname);
        let params = {};
        params = { ...this.search, ...params };
        this.$store.dispatch('performance/fetchTableRows', params);
    },

    methods: {
        async triggerSearch() {
            this.setQueryParams(this.search);
            await this.$store.dispatch('performance/fetchTableRows', {});
        },
        resetSearch() {
            this.$store.commit('performance/resetSearch');
            window.history.replaceState({}, document.title, window.location.pathname);
        },
    },
};
</script>

