<template>
    <section class="table-section">
        <div class="w-full" ref="listen_width"></div>
        <table>
            <thead>
                <tr>
                    <th style="width: 70px">Edit</th>
                    <th>Name</th>
                    <th>Company</th>
                    <th>New</th>
                    <th style="min-width: 104px">Effective</th>
                    <th style="min-width: 104px">Expires</th>
                    <th>Cancelled</th>
                    <th>Renewed</th>
                    <th>Premium</th>
                    <th>Old Premium</th>
                    <th>Adjusted</th>
                    <th>Carrier</th>
                    <th>Type</th>
                    <th>Notes</th>
                </tr>
                <tr class="row-wrapper">
                    <td colspan="8"></td>
                    <td>
                        <div class="row-container">
                            {{ toDollar(premiumTotal) }}
                        </div>
                    </td>
                    <td>
                        <div class="row-container">
                            {{ toDollar(oldPremiumTotal) }}
                        </div>
                    </td>
                    <td>
                        <div class="row-container" ref="row_container">
                            <span>{{ toggleTotal }}</span> 

                            <template v-if="premiumTotal || oldPremiumTotal">
                                <template v-if="isPercent">
                                      <button  type="button"  class="button-percent" title="Premium adjustment" @click="isPercent = !isPercent"></button>
                                </template>
                                <template v-else>
                                      <button  type="button" class="button-dollar" title="Dollar adjustment"  @click="isPercent = !isPercent"></button>
                                </template>
                            </template>
                        </div>
                    </td>
                    <td colspan="3"></td>
                </tr>
            </thead>
            <tbody v-if="rows.length > 0">
                <tr v-for="(row, i) in rows" :key="i">
                    <td style="padding-left: 0; width: 70px">
                        <div class="table-icons">
                            <a href="#" class="edit" title="Edit Policy" @click.prevent="showEdit(row)">
                                <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="-.9 0 24 24" style="background: #047fff">
                                    <g clip-path="url(#clip0_46_43592)">
                                        <path
                                            d="M14.4803 7.38745C15.2954 8.1943 16.0289 8.91882 16.8277 9.7092C15.1812 11.3558 13.6 13.1013 11.8394 14.6162C11.3341 15.0608 10.2907 14.8961 9.49196 14.962C9.42675 14.962 9.24744 14.6162 9.24744 14.4515C9.29634 13.2989 9.49196 12.2615 10.4701 11.4052C11.8883 10.1538 13.1435 8.73769 14.4803 7.38745Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M15.074 6.98489C16.4108 5.27239 17.6986 5.43705 18.4811 7.19895C18.8397 8.0058 18.1551 8.38453 17.7149 8.81265C17.2585 9.27371 17.0628 8.71385 16.8183 8.49979C16.2152 8.0058 15.6446 7.47888 15.074 6.98489Z"
                                            fill="white"
                                        />
                                        <path
                                            d="M14.21 13.1099V15.8927V15.9092C11.8137 15.9092 9.38473 15.9092 6.89058 15.9092C6.89058 13.4392 6.89058 11.0022 6.89058 8.48286C8.39033 8.48286 9.85748 8.417 11.3083 8.49933C12.3353 8.56519 12.8081 7.98887 13.3134 7.23142C13.1667 7.16555 13.0689 7.08322 12.9874 7.08322C10.8356 7.06676 8.70006 7.05029 6.54824 7.08322C5.89617 7.09969 5.53754 7.54428 5.53754 8.23587C5.53754 10.9034 5.53754 13.5545 5.53754 16.222C5.53754 16.9466 5.94508 17.3582 6.64605 17.3582C9.28692 17.3747 11.9115 17.3912 14.5524 17.3253C14.911 17.3088 15.5305 16.8148 15.5631 16.502C15.5794 16.3044 15.5957 16.1068 15.5957 15.9092H15.6283V12.2866H15.0251L14.21 13.1099Z"
                                            fill="white"
                                        />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_46_43592">
                                            <rect width="13.0413" height="11.5923" fill="white" transform="translate(5.53754 5.78223)" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </a>
                        </div>
                    </td>

                    <td>
                        <div class="whitespace-nowrap">{{ row.fullName }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.company }}</div>
                    </td>
                    <td>
                        <div v-if="row.newBusiness" class="flex justify-center w-full"><img :src="getImgUrl('row-check.svg')" /></div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.effective }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.expires }}</div>
                    </td>
                    <td>
                        <div class="text-red-400 whitespace-nowrap">{{ row.cancelled }}</div>
                    </td>
                    <td>
                        <div class="flex justify-center w-full"><img :src="getImgUrl(row.renewed ? 'row-check.svg' : 'row-x.svg')" v-if="row.renewed !== null" /></div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ toDollar(row.premium) }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ toDollar(row.oldPremium) }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ addPercent(row.adjusted) }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.carrier }}</div>
                    </td>
                    <td>
                        <div class="whitespace-nowrap">{{ row.type }}</div>
                    </td>
                    <td>
                        <div :title="row.notes" class="table-notes">{{ row.notes }}</div>
                    </td>
                </tr>
            </tbody>
            <template v-else>
                <tr>
                    <td colspan="19">
                        <div class="text-black text-center py-4">No data found</div>
                    </td>
                </tr>
            </template>
        </table>
    </section>
</template>

<script>
export default {
    props: ['rows'],
    name: 'Table',

    data() {
        return {
            listenWidth: null,
            rowContainer: null,
            isPercent: false,
        };
    },
    methods: {
        showEdit(row) {
            this.$store.commit('policies/setDetails', row);
            this.$store.commit('performance/showEdit');
        },

        showDelete(row) {
            this.$store.commit('policies/setDetails', row);
            this.$store.commit('performance/showDelete');
        },

        moveToCustomer(row) {
            location.assign('/dashboard/customers?name=' + encodeURIComponent(row.fullName));
        },

        addPolicy(customer) {
            this.$store.commit('policies/setDetails', {customer, newBusiness: false});
            this.$store.commit('performance/showAdd');
        },
    },

    computed: {
        premiumTotal() {
            return this.$store.state.performance.table.premiumTotal;
        },
        oldPremiumTotal() {
            return this.$store.state.performance.table.oldPremiumTotal;
        },
        toggleTotal() {
            if (this.isPercent) {
                return this.premiumAdjustedTotal;
            } else {
                return this.dollarAdjustedTotal;
            }
        },
        dollarAdjustedTotal() {
            return this.addPercent(this.$store.state.performance.table.dollarAdjustedTotal);
        },
        premiumAdjustedTotal() {
            return this.addPercent(this.$store.state.performance.table.premiumAdjustedTotal);
        },
    },
};
</script>
<style scoped lang="scss">
.row-wrapper {
    background: rgba(228, 241, 250, 1) !important;
    position: sticky !important;
    top: 39.8px;
    z-index: 2;
    .row-container {
        font-weight: 700;
        font-size: 15px;
        color: #077ffc;
        display: flex;
        align-items: center;
    }
    button {
        width: 19.29px;
        height: 19.29px;
        border-radius: 2.7563px;
        color: white;
        font-size: 0;
        margin-left: 6px;
        &::after {
            font-size: 1rem;
            font-weight: 700;
            font-size: 10.3361px;
        }
    }
    .button-percent {
        background: #047fff;
        &::after {
            content: 'P';
        }
        &:hover {
            background: #3a9bff;
        }
        &:active {
            background: #0379f3;
        }
    }
    .button-dollar {
        background: #33c500;
        &::after {
            content: 'D';
        }
        &:hover {
            background: #4de219;
        }
        &:active {
            background: #2fb400;
        }
    }
}
</style>
