<template>
    <SearchableServerSide1
        v-model="option"
        :disabled="disabled"
        :required="required"
        :disableError="true"
        :loading="loading"
        @selectOption="select"
        title="Carrier"
        placeholder="Type Carrier"
        @search="search"
        :options="options"
    />
</template>

<script>

import SearchableServerSide1 from '@/components/SearchableServerSide1';
export default {
    components: {SearchableServerSide1},
    props: ['disabled', 'required'],
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        options() {
            const list = this.$store.state.policies.carrierList.map((item) => {
                return {
                    title: item,
                    value: item,
                    object: {carrier: item},
                };
            });
            return list;
        },

        modal() {
            return this.$store.state.policies.modal.details;
        },

        option: {
            get() {
                return {
                    title: this.modal.carrier,
                    value: this.modal.carrier,
                    object: this.modal,
                };
            },
            set(v) {
                this.$store.commit('performance/setSearch', {carrier: v});
            },
        },
    },

    methods: {
        select({carrier}) {
            this.loading = false;

         this.$store.commit('performance/setSearch', {carrier});

        },
        async search(carrier) {
            const emptyData = () => {
                this.$store.commit('policies/setCarrierList', []);
            };

           this.$store.commit('performance/setSearch', {carrier});

            if (carrier && carrier.length >= 2) {
                this.loading = true;
                const hasRows = await this.$store.dispatch('policies/carrierList', {carrier});
                if (!hasRows) {
                    // If none is fetched, empty customerId
                    emptyData();
                }

                this.loading = false;
            } else {
                emptyData();
            }
        },
        },
    };
</script>
