<template>
    <Modal type="Delete Policy" @submitData="submitData" :isDelete="true" />
</template>

<script>
import Modal from './Index';
import axios from 'axios';

export default {
    components: { Modal },
    methods: {
        async submitData() {
            this.loader();
            const { id } = this.$store.state.policies.modal.details;
            await this.$store.dispatch('policies/deleteTableRow', { id });
            this.loader(false);

            if(this.$route.name === 'policies') {
              await this.$store.dispatch('policies/fetchTableRows');
            }
            
            if(this.$route.name === 'performance') {
              await this.$store.dispatch('performance/fetchTableRows');
            }

            this.$store.commit('policies/hideModal');
            this.$store.commit('performance/hideModal');
        },
    },
};
</script>
