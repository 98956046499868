<template>
    <teleport to="head">
        <title>Performance - Rovner Apps</title>
    </teleport>
    <Main>
        <template v-if="modalComponent">
            <component :is="modalComponent" />
        </template>

        <SearchBar />
        <div class="text-center table-container flex-1">
            <Table :rows="rows" />
        </div>
        <div class="pagination">
            <PerPage :pagination="pagination" @fetchData="fetchData" />
             <NoOfRows  :pagination="pagination" singular="Policy" plural="Policies"/>
            <Pagination :pagination="pagination" @fetchData="fetchData" />
        </div>
    </Main>
</template>

<script>
import Main from '../../components/Main';
import Table from './table/Index.vue';
import SearchBar from './SearchBar.vue';
import Pagination from '../../components/Pagination';
import PerPage from '../../components/PerPage';
import NoOfRows from '../../components/NoOfRows';

import Add from '../policies/modal/Add';
import Delete from '../policies/modal/Delete';
import Edit from '../policies/modal/Edit';

export default {
    components: { Main, Table, Pagination, PerPage, SearchBar, Add, Delete, Edit, NoOfRows },
    data() {
        return {};
    },

    computed: {
        rows() {
            return this.$store.getters['performance/getTableRows'];
        },
        pagination() {
            return this.$store.getters['performance/getTablePagination'];
        },
        modalComponent() {
            return this.$store.state.performance.modal.component;
        },
    },
    mounted() {
    },

    methods: {
        async fetchData(obj) {
            if('perpage' in obj) {
                localStorage.setItem('performance_per_page', obj.perpage);
                delete obj.perpage
            }
            if (obj) {
                this.$store.commit('performance/setSearch', obj);
            }
            await this.$store.dispatch('performance/fetchTableRows', obj);
        },
    },
};
</script>
<style scoped lang="scss">


</style>
<style>
body {
     min-width: 700px;
}
</style>
